<template>
  <div class="box_content">

    <div class="table_container">
      <table class="table team-table list-content">

        <thead class=" ">
        <tr>
          <th style="width: 300px;">Profile</th>
          <th>Email</th>
          <th style="width: 163px;text-align: right;">Actions</th>
        </tr>
        </thead>

        <tbody class="list-content-body">
        <tr v-if="getClientLoader.loader">
          <td colspan="4">
            <div style="padding-top: 15px;">
              <clip-loader :size="size" :color="color"></clip-loader>
            </div>
          </td>
        </tr>

        <template v-else>
          <tr v-if="getClients.length === 0">
            <td colspan="4">
              You do not have any client added yet.
            </td>
          </tr>
          <tr v-for="client in getClients">
            <td>
              <div class="profile_box_x clear">
                <div class="d_icon"
                     :style="{ 'background': 'url(' + backgroundImage(client) + ')' }">
                </div>
                <div class="d_content">
                  <p class="content_heading">{{ client.first_name }} {{ client.last_name }}</p>
                </div>
              </div>
            </td>
            <td>
              {{ client.email }}
            </td>
            <td class="table-action ">
              <!--removeMember(member._id)-->
              <i v-tooltip="'Remove'"
                 class="far fa-trash "
                 aria-hidden="true"
                @click="deleteClient(client._id)">
              </i>
            </td>
          </tr>
        </template>

        </tbody>

      </table>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import http from '@/mixins/http-lib'
import { deleteWhitelabelClient } from '../../../../config/config'
import { swalDefaultValues } from '../../../../common/attributes'

export default {
  data() {
    return {
      size: '17px'
    }
  },
  created() {

  },
  computed: {
    ...mapGetters(['getClients', 'getClientLoader'])
  },
  methods:{
    deleteClient(id) {
      let confirmation = swalDefaultValues()
      var self = this
      swal(confirmation,
        function (isConfirm) {
          if (isConfirm) {
            http.post(`${deleteWhitelabelClient}`, {id}).then(
              resp => {
                if (resp.data.status) {
                  self.$store.dispatch('toastNotification', { message: resp.data.message })
                  self.$store.dispatch('fetchClients')
                }
              },
              error => {
                self.$store.dispatch('toastNotification', { type: 'error', message: error.error.message })
              }
            )
          } else {
            swal()
          }
        }
      )

    }
  }
}
</script>

<style scoped lang="less">
.status {
  width: 120px;
  overflow: hidden;
  display: block;
}
</style>
