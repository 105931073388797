<template>
  <div class="setting_form clear">
    <add-client v-if="clientModal"></add-client>
    <div class="white_box">
      <div class="box_inner">
        <div class="box_head d-flex align-items-center">
          <div class="left">
            <h2>Clients</h2>
          </div>
          <div class="right ml-auto">
            <template>
              <button @click.prevent="addClientModal()"
                      class="btn---cta btn-blue with-shadow btn-round   btn-bold">
                <span>Add Client</span>
                <span class="icon_right">
                               <i
                                 class="circle_icon d-flex align-items-center justify-content-center  fal fa-plus  "></i>
                            </span>
              </button>
            </template>
          </div>
        </div>
        <clients-list></clients-list>
      </div>
    </div>
  </div>
</template>

<script>
import ClientsList from './ClientsList.vue'
import AddClient from './AddClient.vue'
import { DARK_PURPLE_LOADER_COLOR } from '@/common/constants'
export default {
  name: 'clients',
  data () {
    return {
      size: '17px',
      color: DARK_PURPLE_LOADER_COLOR,
      clientModal: false
    }
  },
  created () {
    this.$store.dispatch('fetchClients')
  },
  components: {
    ClientsList,
    AddClient
  },
  methods: {
    addClientModal () {
      this.clientModal = true
      setTimeout(() => {
        this.$bvModal.show('modalAddClient')
      }, 100)
    },
    closeAddClientModal () {
      this.$bvModal.hide('modalAddClient')
      this.$store.dispatch('fetchClients')
    }
  }
}
</script>
<style lang="less">
@import "../../../../assets/less/colors.less";

</style>
