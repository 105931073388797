<template>
  <b-modal modal-class="modal_basic modalAddTeam" id="modalAddClient" hide-footer hide-header>
    <img @click="close()" class="close_modal" src="/assets/img/cross.svg" alt="">

    <div class="basic_form modal_content" v-on-clickaway="close">

      <div class="heading">
        <h3>Add Client</h3>
      </div>

      <form class=" clear">
        <div class="row">
          <div class="col-md-6 pl-0 pr-1">
            <div :class="{'input-field-error': validations.first_name || validations.first_name_length}"
                 class=" mt-3 input_icon_text d-flex align-items-center profile__box">
              <div class="content">
                <div class="url">First Name</div>
                <div class="name">
                  <div class="validation_input">
                    <input type="text" v-model="client.first_name"
                           placeholder="Enter client first name">
                  </div>
                </div>
              </div>
            </div>
            <span class="input-error" v-if="validations.first_name">{{ messages.first_name }}</span>
            <span class="input-error" v-else-if="validations.first_name_length">{{ messages.first_name_length }}</span>
          </div>
          <div class="col-md-6 pr-0 pl-1">
            <div :class="{'input-field-error': validations.last_name || validations.last_name_length}"
                 class=" mt-3  input_icon_text d-flex align-items-center profile__box">
              <div class="content">
                <div class="url">Last Name</div>
                <div class="name">
                  <div class="validation_input">
                    <input type="text" v-model="client.last_name"
                           placeholder="Enter client last name">
                  </div>
                </div>
              </div>
            </div>
            <span class="input-error" v-if="validations.last_name">{{ messages.last_name }}</span>
            <span class="input-error" v-else-if="validations.last_name_length">{{ messages.last_name_length }}</span>
          </div>
        </div>

        <div class="row">

          <div class="col-md-6 pl-0 pr-1">
            <div :class="{'input-field-error': validations.email || validations.email_length}"
                 class="mt-3  input_icon_text d-flex align-items-center profile__box">
              <div class="content">
                <div class="url">Email Address</div>
                <div class="name">
                  <div class="validation_input">
                    <input type="email" v-model="client.email"
                           placeholder="Enter your client email">
                  </div>
                </div>
              </div>
            </div>
            <span class="input-error" v-if="validations.email">{{ messages.email }} </span>
            <span class="input-error" v-else-if="validations.email_length">{{ messages.email_length }} </span>
          </div>
        </div>
      </form>

      <div class=" btn_block text-left">
        <button
          class="btn---cta btn-blue with-shadow btn-round  btn--loader white--loader loader--right btn-bold"
          @click="save()">
          <span>Add</span>
        </button>
        <button type="button" class="ml-2 btn---cta light-blue btn-round  btn-bold"
                @click="close()"><span>Close</span>
        </button>

      </div>

    </div>
  </b-modal>
</template>
<script>

import http from '@/mixins/http-lib'
import { saveWhitelabelClient } from '../../../../config/config'

export default ({
  props: ['show_modal'],
  data () {
    return {
      client: {
        first_name: '',
        last_name: '',
        email: ''
      },
      validations: {
        first_name: false,
        first_name_length: false,
        last_name: false,
        last_name_length: false,
        email: false,
        email_length: false
      },
      messages: {
        first_name: 'First name is required.',
        first_name_length: 'The first name should not be greater than 100 characters',
        last_name: 'Last name is required.',
        last_name_length: 'The last name should not be greater than 100 characters',
        email: 'Email address is required.',
        email_length: 'The email should not be greater than 100 characters'
      }
    }
  },
  created () {
  },
  computed: {},
  methods: {
    close () {
      this.$parent.closeAddClientModal()
    },
    save () {
      this.validations.first_name = this.requiredCheck(this.client.first_name)
      this.validations.last_name = this.requiredCheck(this.client.last_name)
      this.validations.email = this.checkEmail(this.client.email)
      let result = Object.keys(this.validations).every(k => this.validations[k] === false)
      if (result) {
        http.post(`${saveWhitelabelClient}`, this.client).then(
          resp => {
            if (resp.data.status) {
              this.$store.dispatch('toastNotification', { message: resp.data.message })
              this.$parent.closeAddClientModal()
            } else {
              this.$store.dispatch('toastNotification', { type: 'error', message: resp.data.message })
            }
          },
          error => {
            this.$store.dispatch('toastNotification', { type: 'error', message: error.error.message })
          }
        )
      }
    }
  },
  watch: {
    'client.first_name' (value) {
      if (value && value.length > 0) {
        this.validations.first_name = false
        this.validations.first_name_length = !this.maxLength(value, 100)
      }
    },
    'client.last_name' (value) {
      if (value && value.length > 0) {
        this.validations.last_name = false
        this.validations.last_name_length = !this.maxLength(value, 100)
      }
    },
    'client.email' (value) {
      if (value && value.length > 0) {
        this.validations.email = false
        this.validations.email_length = !this.maxLength(value, 100)
      }
    }
  }
})
</script>
